import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Route, Switch} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/cjs/Col";
import {Button} from "react-bootstrap";
import history from "../../../history";
import {injectIntl} from "react-intl";

class PrivacyPolicy extends Component {

    state = {}


    renderContent = () => {
        return (
            <div>
                asdf
            </div>
        )
    }

    renderPage = () => {
        const {intl} = this.props;
        return (
            <div className='administrative__detail-page'>
                <div className='inner-helper-page terms'>
                    <Container>
                        <Row>
                            <Col lg={8}>
                                <div className='back-link'>
                                    <Button
                                        variant="link"
                                        className="link cta_outline cta_outline__dark"
                                        onClick={() => {
                                            history.goBack()
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                "<spam class='mr-3'>" +
                                                "<svg width=\"65\" height=\"8\" viewBox=\"0 0 65 8\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
                                                "<path d=\"M0.646446 3.64645C0.451187 3.84171 0.451187 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM65 3.5L1 3.5V4.5L65 4.5V3.5Z\" />\n" +
                                                "</svg>" +
                                                "</spam>"
                                                + intl.formatMessage({id: 'back'})
                                        }}>
                                    </Button>
                                </div>

                                <div className='inner-helper-page__title'>
                                    <h4 className='h4'>ПОЛИТИКА ЗА ПОВЕРИТЕЛНОСТ <br/> НА САЙТА museumsmolyan.eu</h4>
                                </div>
                                <div className='inner-helper-page__text'>
                                    <p className='paragraph-3'>
                                        С настоящите правила относно политиката за поверителност бихме желали да Ви
                                        информираме за условията, при които личните Ви данни, получени при или по повод
                                        посещение на нашия сайт <a className='link link-underline link-default'
                                                                   href="https://museumsmolyan.eu">https://museumsmolyan.eu</a> или
                                        в рамките на друг вид
                                        взаимодействие с нас, се обработват.
                                    </p>
                                    <p className='paragraph-3'>
                                        Регионален исторически музей „Стою Шишков” – Смолян, наричн по-долу Дружеството,
                                        e собственик на този уебсайт <a className='link link-underline link-default'
                                                                        href="https://museumsmolyan.eu">https://museumsmolyan.eu</a>.
                                    </p>
                                    <p className='paragraph-3'>
                                        В рамките на дейността си Дружеството обработва лични данни на физически лица
                                        съгласно действащото българско и европейско законодателство, поради което,
                                        доколкото само определя целите и средствата за тяхното обработване, има
                                        качеството на администратор на лични данни.
                                    </p>
                                    <p className='paragraph-3'>
                                        Дружеството е със седалище и адрес на управление в Адрес: гр. Смолян 4700,
                                        ул.”Дичо Петров” №5.
                                    </p>
                                    <p className='paragraph-3'>
                                        На посочения адрес можете да изпращате лично или чрез изрично, писмено
                                        упълномощено от Вас лице своите заявления, възражения, искания, жалби и други,
                                        както и по електронен път при спазване изискванията на ЗЕДЕП за използване на
                                        електронен подпис на електронен адрес rim.smolyan@gmail.com на вниманието на
                                        Валентина Василева.
                                    </p>
                                    <div className='mt-5 mb-3'>
                                        <h4 className='h5'>I. ПОНЯТИЯ:</h4>
                                    </div>
                                    <p className='paragraph-3'>
                                        За яснота на настоящите правила, предлагаме на вниманието Ви някои основни
                                        дефиниции по смисъла на Регламент (ЕС) 2016/679 на Европейския парламент и на
                                        Съвета от 27 април 2016 година относно защитата на физическите лица във връзка с
                                        обработването на лични данни и относно свободното движение на такива данни и за
                                        отмяна на Директива 95/46/EО (Общ регламент относно защитата на данните; ОРЗД),
                                        касаещи Вашите лични данни:
                                    </p>
                                    <p className='paragraph-3'>
                                        1.1. „лични данни“ означава всяка информация, свързана с идентифицирано
                                        физическо лице или физическо лице, което може да бъде идентифицирано („субект на
                                        данни“); физическо лице, което може да бъде идентифицирано, е лице, което може
                                        да бъде идентифицирано, пряко или непряко, по-специално чрез идентификатор като
                                        име, идентификационен номер, данни за местонахождение, онлайн идентификатор или
                                        по един или повече признаци, специфични за физическата, физиологичната,
                                        генетичната, психическата, умствената, икономическата, културната или социална
                                        идентичност на това физическо лице;
                                    </p>
                                    <p className='paragraph-3'>
                                        1.2. „обработване“ означава всяка операция или съвкупност от операции,
                                        извършвана с лични данни или набор от лични данни чрез автоматични или други
                                        средства като събиране, записване, организиране, структуриране, съхранение,
                                        адаптиране или промяна, извличане, консултиране, употреба, разкриване чрез
                                        предаване, разпространяване или друг начин, по който данните стават достъпни,
                                        подреждане или комбиниране, ограничаване, изтриване или унищожаване;

                                    </p>
                                    <p className='paragraph-3'>
                                        1.3. „администратор на лични данни“ означава физическо или юридическо лице,
                                        публичен орган, агенция или друга структура, която сама или съвместно с други
                                        определя целите и средствата за обработването на лични данни; когато целите и
                                        средствата за това обработване се определят от правото на Съюза или правото на
                                        държава членка, администраторът или специалните критерии за неговото определяне
                                        могат да бъдат установени в правото на Съюза или в правото на държава членка;
                                    </p>
                                    <p className='paragraph-3'>
                                        1.4. „обработващ лични данни“ означава физическо или юридическо лице, публичен
                                        орган, агенция или друга структура, която обработва лични данни от името на
                                        администратора;
                                    </p>
                                    <p className='paragraph-3'>
                                        1.5. „съгласие на субекта на данните“ означава всяко свободно изразено,
                                        конкретно, информирано и недвусмислено указание за волята на субекта на данните,
                                        посредством изявление или ясно потвърждаващо действие, което изразява съгласието
                                        му свързаните с него лични данни да бъдат обработени;

                                    </p>
                                    <div className='mt-5 mb-3'>
                                        <h4 className='h5'>II. ПРИНЦИПИ ПРИ ОБРАБОТВАНЕ НА ЛИЧНИ ДАННИ:</h4>
                                    </div>
                                    <p className='paragraph-3'>
                                        2.1. Законосъобразност – обработваме Вашите лични данни изцяло в съответствие
                                        със закона и за цели, които не противоречат на действащи правни норми
                                    </p>
                                    <p className='paragraph-3'>
                                        2.2. Добросъвестност – обработваме Вашите лични данни доколкото същите са
                                        необходими единствено за осигуряване на по-добро изживяване с нашите он-лайн
                                        продукти и осигуряване на ефективното ни партньорство с Вас;
                                    </p>
                                    <p className='paragraph-3'>
                                        2.3. Прозрачност – не обработваме лични данни по начини, за които не сме Ви
                                        информирали с настоящите правила и/или изрично при друга комуникация с Вас и сме
                                        отворени да отговорим в обичайното ни работно време на каквито и да било въпроси
                                        относно личните Ви данни в разумен срок;
                                    </p>
                                    <p className='paragraph-3'>
                                        2.4. Ограничение на целите – обработваме личните Ви данни единствено за
                                        посочените по-долу основания в раздел III.
                                    </p>
                                    <p className='paragraph-3'>
                                        2.5. Свеждане на данните до минимум – обработваме единствено тези Ваши лични
                                        данни, без които целите на обработването им не могат да бъдат постигнати;
                                    </p>
                                    <p className='paragraph-3'>
                                        2.6. Точност – стремим се да поддържаме и актуализираме обработваните от нас
                                        лични данни, каквито сте ни ги предоставили, отговарящи на обективната
                                        действителност;
                                    </p>
                                    <p className='paragraph-3'>
                                        2.7. Ограничение на съхранението – съхраняваме личните Ви данни единствено
                                        доколкото и докогато са изпълнени целите на обработването им;
                                    </p>
                                    <p className='paragraph-3'>
                                        2.8. Цялостност и поверителност – обработваме личните Ви данни по начина и при
                                        условията, съгласно които сме ги получили, при съблюдаване на строга
                                        конфиденциалност. Не предоставяме Вашите данни на други лица и не ги правим
                                        публично достояние, освен в изрично предвидените от закона случаи или с Ваше
                                        изрично съгласие.
                                    </p>
                                    <div className='mt-5 mb-3'>
                                        <h4 className='h5'>III. ОСНОВАНИЯ ЗА ОБРАБОТВАНЕ НА ЛИЧНИ ДАННИ:</h4>
                                    </div>
                                    <p className='paragraph-3'>
                                        При спазване на принципите в раздел II, обработваме личните Ви данни, които сме
                                        получили от Вас, защото:
                                    </p>
                                    <p className='paragraph-3'>
                                        3.1. сте ни дали Вашето изрично, предварително съгласие за това, за цели, за
                                        които сме Ви информирали предварително или които Вие сами сте дефинирали.
                                        Например, когато искаме да Ви държим в течение с нашите новини, продукти и
                                        кампании, ще се нуждаем съгласието Ви да получим Вашия имейл адрес, на който да
                                        изпращаме тази информация. В други случаи, при които считаме, че сме получили
                                        Вашето съгласие за обработване на личните Ви данни е например, когато сте ни
                                        изпратили писмо на адреса за кореспонденция или имейл, в които сами сте посочили
                                        лични данни, защото сте счели това за необходимо. (Правно основание: чл. 6, пар.
                                        1, буква „а“ от ОРЗД).
                                    </p>
                                    <p className='paragraph-3'>
                                        Във всеки случай на получено съгласие от Ваша страна за обработване на Ваши
                                        лични данни на това основание, можете напълно безплатно и във всеки един момент
                                        да оттеглите това съгласие като използвате формата ни за контакт или ни
                                        изпратите писмо или съобщение, от което недвусмислено става ясно, че сте
                                        оттеглили даденото съгласие.
                                    </p>
                                    <p className='paragraph-3'>
                                        3.2. личните Ви данни са ни необходими, за да сключим договор с Вас и да
                                        обезпечим неговото изпълнение. Например, за да сключим договор за реклама, ще са
                                        ни необходими най-малко трите Ви имена ЕГН или друг идентификатор, ако е
                                        приложим, адрес по лична карта, информация за контакт (Правно основание: чл. 6,
                                        пар. 1, буква „б“ от ОРЗД);
                                    </p>
                                    <p className='paragraph-3'>
                                        3.3. законодателството ни задължава да събираме някои лични данни за Вас в
                                        изпълнение на наши нормативни задължения. Например, ако сте наш служител, в
                                        изпълнение на задълженията си като работодател, следва да обработим допълнителна
                                        информация за осигуряване подходящи условия съгласно изискванията на трудовата
                                        медицина (Правно основание: чл. 6, пар. 1, буква „в“ от ОРЗД);
                                    </p>
                                    <p className='paragraph-3'>
                                        3.4. личните Ви данни са ни необходими, за да защитим Вашите жизнено важни
                                        интереси или тези на друго физическо лице. Например в случай на обявено за
                                        издирване лице или защита права на трети лица по повод изложени от Вас коментари
                                        в рамките на някои от нашите материали (Правно основание: чл. 6, пар. 1, буква
                                        „г“ от ОРЗД);

                                    </p>
                                    <p className='paragraph-3'>
                                        3.5. обработването на личните Ви данни ни е необходимо за изпълнението на задача
                                        от обществен интерес или при упражняването на официални правомощия, които са ни
                                        предоставени от публичен орган (Правно основание: чл. 6, пар. 1, буква „д“ от
                                        ОРЗД);
                                    </p>
                                    <p className='paragraph-3'>
                                        3.6. обработването на личните Ви данни има за цел да защити наш легитимен
                                        интерес или на други лица, доколкото тези интереси нямат преимущество над Вашите
                                        основни права и свободи. Пример за такова обработване са предявени срещу нас
                                        претенции от трети лица във връзка с Ваши коментари под наши публикации или
                                        защита на правата ни пред държавни и съдебни органи във връзка с Ваше договорно
                                        или извъндоговорно поведение, което е довело до увреждане на нашите права и
                                        интереси (Правно основание: чл. 6, пар. 1, буква „е“ от ОРЗД).
                                    </p>
                                    <div className='mt-5 mb-3'>
                                        <h4 className='h5'>IV. СРОК НА ОБРАБОТВАНЕ И СЪХРАНЕНИЕ:</h4>
                                    </div>
                                    <p className='paragraph-3'>
                                        Съхраняваме личните Ви данни за посочените в раздел III основания за максимално
                                        допустимите от законодателството периоди в зависимост от конкретния случай и вид
                                        данни, за които се отнасят. Доколкото в нормативен акт не е предвиден по-дълъг
                                        или по-кратък срок за съхранение, то съхраняваме личните Ви данни за период не
                                        по-дълъг от 5 години съгласно общата погасителна давност по Закона за
                                        задълженията и договорите.

                                    </p>
                                    <p className='paragraph-3'>
                                        В някои случаи, законодателството изисква по-дълги срокове за съхранение, като
                                        например норми в областта на счетоводството. В други случаи, например при
                                        представяне на документи за кандидатстване за работа при нас, законодателството,
                                        предвижда максимален срок за съхранение и обработване на получените лични данни
                                        по подадените кандидатури от 6 месеца.
                                    </p>
                                    <p className='paragraph-3'>
                                        В случаи на съдебни спорове, съхраняваме данните Ви до 5 години след тяхното
                                        приключване.
                                    </p>
                                    <div className='mt-5 mb-3'>
                                        <h4 className='h5'>V. ВАШИТЕ ПРАВА:</h4>
                                    </div>
                                    <p className='paragraph-3'>
                                        5.1. Във всеки момент можете да поискате информация и съдействие, касаеща Ваши
                                        лични данни, обработвани от нас чрез посоченатa по-горе информация за контакт и
                                        да заявите предпочитан от Вас начин за получаването й. Ще Ви отговорим не
                                        по-късно от един месец от получаване на съответното искане от Ваша страна по
                                        заявения от Вас начин, доколкото това е възможно. В зависимост от конкретния вид
                                        искане, може да се нуждаем от допълнителна информация, за да потвърдим Вашата
                                        самоличност, за което ще Ви уведомим своевременно.
                                    </p>
                                    <p className='paragraph-3'>
                                        5.2. Във всеки момент имате право да поискате от нас да потвърдим дали
                                        обработваме Ваши лични данни и ако това е така да Ви ги предоставим наред с
                                        информация относно:
                                    </p>
                                    <p className='paragraph-3'>
                                        a) целите на обработване;
                                    </p>
                                    <p className='paragraph-3'>
                                        б) съответните категории лични данни;
                                    </p>
                                    <p className='paragraph-3'>
                                        в) получателите или категориите получатели, пред които са или ще бъдат разкрити
                                        личните данни, по-специално получателите в трети държави или международни
                                        организации;
                                    </p>
                                    <p className='paragraph-3'>
                                        г) когато е възможно, предвидения срок, за който ще се съхраняват личните данни,
                                        а ако това е невъзможно, критериите, използвани за определянето на този срок;
                                    </p>
                                    <p className='paragraph-3'>
                                        д) съществуването на правото Ви да изисквате от нас коригиране или изтриване на
                                        лични данни или ограничаване на обработването им, свързани Вас, или да направите
                                        възражение срещу такова обработване;
                                    </p>
                                    <p className='paragraph-3'>
                                        е) правото Ви на жалба до надзорен орган, който за България е Комисията за
                                        защита на личните данни и данните за контакт с нея са посочени в раздел X;
                                    </p>
                                    <p className='paragraph-3'>
                                        ж) когато личните данни не са предоставени от Вас, всякаква налична информация
                                        за техния източник;
                                    </p>
                                    <p className='paragraph-3'>
                                        з) дали съществува автоматизирано вземане на решения, включително профилиране,
                                        ведно със съществена информация относно използваната логика, както и значението
                                        и предвидените за Вас последствия от това обработване.
                                    </p>
                                    <p className='paragraph-3'>
                                        5.3. Във всеки момент може да поискате от нас да коригираме без ненужно забавяне
                                        Вашите лични данни, които са неточни и/или непълни.

                                    </p>
                                    <p className='paragraph-3'>
                                        5.4. Ние гарантираме правото Ви да поискате да изтрием личните Ви данни без
                                        ненужно забавяне доколкото:
                                    </p>
                                    <p className='paragraph-3'>
                                        a) личните данни повече не са необходими за целите, за които са били събрани или
                                        обработвани по друг начин;
                                    </p>
                                    <p className='paragraph-3'>
                                        б) оттеглили сте своето съгласие, върху което се основава обработването на
                                        данните и няма друго правно основание за обработването;
                                    </p>
                                    <p className='paragraph-3'>
                                        в) възразите срещу обработването по всяко време и на основания, свързани с
                                        вашата конкретна ситуация доколкото то е необходимо за изпълнението на задача от
                                        обществен интерес или при упражняването на официални правомощия, които са ни
                                        предоставени или обработването е необходимо за целите на нашите легитимни
                                        интереси или на трета страна, освен когато пред такива интереси преимущество
                                        имат Вашите интереси или основни права и свободи, които изискват защита на
                                        личните данни, по-специално когато сте дете.
                                    </p>
                                    <p className='paragraph-3'>
                                        Необходимо условие за уважаване на искането Ви в този случай е да няма законни
                                        основания за обработването на данните Ви, които да имат преимущество, или да сте
                                        възразили срещу обработването на данните Ви за целите на директния маркетинг;

                                    </p>
                                    <p className='paragraph-3'>
                                        г) личните данни са били обработвани незаконосъобразно;
                                    </p>
                                    <p className='paragraph-3'>
                                        д) личните данни трябва да бъдат изтрити с цел спазването на правно задължение
                                        по правото на Европейския съюз или правото на държава членка, което се прилага
                                        спрямо нас;
                                    </p>
                                    <p className='paragraph-3'>
                                        е) личните данни са били събрани във връзка с предлагането на услуги на
                                        информационното общество на лица под 16 години.
                                    </p>
                                    <p className='paragraph-3'>
                                        5.5. Имате право да поискате от нас да ограничим обработването на Ваши лични
                                        данни, доколкото:

                                    </p>
                                    <p className='paragraph-3'>
                                        a) оспорвате точността на личните данни, за разумен срок, който ни позволява да
                                        проверим точността на личните данни;

                                    </p>
                                    <p className='paragraph-3'>
                                        б) обработването е неправомерно, но не желаете личните Ви данни да бъдат
                                        изтрити, а искате вместо това ограничаване на използването им;

                                    </p>
                                    <p className='paragraph-3'>
                                        в) не се нуждаем повече от личните данни за целите на обработването, но те са Ви
                                        необходими за установяването, упражняването или защитата на правни претенции;

                                    </p>
                                    <p className='paragraph-3'>
                                        г) възразили сте срещу обработването съгласно член 5.4., буква в) в очакване на
                                        проверка дали нашите законни основания имат преимущество пред Вашите интереси.
                                    </p>
                                    <p className='paragraph-3'>
                                        5.6. Имаме законово задължение да Ви уведомим във всеки случай на извършено
                                        коригиране или изтриване или ограничаване на обработването на Вашите лични
                                        данни.

                                    </p>
                                    <p className='paragraph-3'>
                                        5.7. Имате право да получите личните си данни в структуриран, широко използван и
                                        пригоден за машинно четене формат и да поискате да прехвърлим тези данни на друг
                                        администратор доколкото това не ни възпрепятства, при условие, че сме получили
                                        личните Ви данни на основание дадено от Вас съгласие за обработване или при за
                                        изпълнение на договорни задължения и ние обработваме тези данни по автоматизиран
                                        начин.
                                    </p>
                                    <p className='paragraph-3'>
                                        5.8. Можете да възразите по всяко време и на основания, свързани с вашата
                                        конкретна ситуация срещу обработване на личните Ви данни, доколкото
                                        обработването е необходимо за изпълнението на задача от обществен интерес или
                                        при упражняването на официални правомощия, които са ни предоставени или
                                        обработването е необходимо за целите на нашите легитимни интереси или на трета
                                        страна, освен когато пред такива интереси преимущество имат Вашите интереси или
                                        основни права и свободи, които изискват защита на личните данни, по-специално
                                        когато сте дете, включително при профилиране. В този случай ние ще прекратим
                                        обработването на личните Ви данни, освен ако не съществуват убедителни законови
                                        основания за обработването, които имат предимство пред Вашите интереси, права и
                                        свободи, или са свързани с за установяването, упражняването или защитата на
                                        правни претенции.
                                    </p>
                                    <p className='paragraph-3'>
                                        5.9. Имате право по всяко време да възразите срещи обработване на личните Ви
                                        данни за целите на директния маркетинг като обработването се прекратява от
                                        момента на получаването на Вашето възражение.
                                    </p>
                                    <p className='paragraph-3'>
                                        Следва да имате предвид, че упражняването на правата Ви по настоящия раздел не е
                                        абсолютно и може да Ви бъде отказано, ако съгласно предвидени в
                                        законодателството условия упражняването им би създало риск за:
                                    </p>
                                    <p className='paragraph-3'>
                                        1. националната сигурност;
                                    </p>
                                    <p className='paragraph-3'>
                                        2. отбраната;
                                    </p>
                                    <p className='paragraph-3'>
                                        3. обществения ред и сигурност;
                                    </p>
                                    <p className='paragraph-3'>
                                        4. предотвратяването, разследването, разкриването или наказателното преследване
                                        на престъпления или изпълнението на наложените наказания, включително
                                        предпазването от и предотвратяването на заплахи за обществения ред и сигурност;
                                    </p>
                                    <p className='paragraph-3'>
                                        5. други важни цели от широк обществен интерес и по-специално важен икономически
                                        или финансов интерес, включително паричните, бюджетните и данъчните въпроси,
                                        общественото здраве и социалната сигурност;

                                    </p>
                                    <p className='paragraph-3'>
                                        6. защитата на независимостта на съдебната власт и съдебните производства;
                                    </p>
                                    <p className='paragraph-3'>
                                        7. предотвратяването, разследването, разкриването и наказателното преследване на
                                        нарушения на етичните кодекси при регулираните професии;

                                    </p>
                                    <p className='paragraph-3'>
                                        8. Вашата защита или на правата и свободите на други лица;
                                    </p>
                                    <p className='paragraph-3'>
                                        9. изпълнението по гражданскоправни искове.
                                    </p>
                                    <p className='paragraph-3'>
                                        За да упражните правата си по настоящия раздел, следва да ни изпратите писмено
                                        заявление на посочения по-горе адрес или на посочената електронна поща.
                                    </p>

                                    <div className='mt-5 mb-3'>
                                        <h4 className='h5'>IV. ЛИЧНИТЕ ДАННИ, КОИТО ОБРАБОТВАМЕ:</h4>
                                    </div>
                                    <p className='paragraph-3'>
                                        6.1. Идентификационни данни : трите имена, единен граждански номер или личен
                                        номер на чужденец, постоянен адрес – тези данни обработваме основно при
                                        встъпването в договорни отношения с Вас;
                                    </p>
                                    <p className='paragraph-3'>
                                        6.2. Други данни:
                                    </p>
                                    <p className='paragraph-3'>
                                        - информация за вида и съдържанието на договорното отношение, както и всяка
                                        друга информация, свързана с договорното правоотношение, включително
                                        медия-планове, електронна поща, писма, информация за заявките ви за отстраняване
                                        на проблеми, жалби, молби, оплаквания; обратна връзка, която получаваме от вас;

                                    </p>
                                    <p className='paragraph-3'>
                                        - лични данни за контакт - адрес за контакт, телефонен номер и информация за
                                        контакт (имейл, телефонен номер);
                                    </p>
                                    <p className='paragraph-3'>
                                        - предпочитания за услугите, които ви предоставяме;

                                    </p>
                                    <p className='paragraph-3'>
                                        - IP адрес при посещение на интернет страницата ни;
                                    </p>
                                    <p className='paragraph-3'>
                                        - данни за профил в социални мрежи, данни, предоставени при участие в игри,
                                        томболи и/или други сезонни или промоционални кампании, организирани от нас,
                                        включително и чрез социалните мрежи;

                                    </p>
                                    <p className='paragraph-3'>
                                        - данни за активността – данни, които се обработват в мрежата за отчитане на
                                        предпочитанията на потребителите.
                                    </p>
                                    <p className='paragraph-3'>
                                        Възможно е да обработваме информация за вас от други законни източници с цел да
                                        Ви предоставим качествено нашите услуги. Такива източници са информация от наши
                                        партньори (в случай, че вие сте се съгласили) или публично достъпна информация.
                                    </p>
                                    <p className='paragraph-3'>
                                        Тази информация включва:
                                    </p>
                                    <p className='paragraph-3'>
                                        - данни за осъществяване на контакт с вас;
                                    </p>
                                    <p className='paragraph-3'>
                                        - публично достъпна информация като например информация, съдържаща се в публични
                                        регистри, медийни публикации, информация, изнесена публично от органи на власт.
                                    </p>
                                    <p className='paragraph-3'>
                                        Във всички случаи, при които не сме получили лични данни лично от самите Вас,
                                        при осъществяване на контакт с Вас, Ви информираме откъде и какви лични данни
                                        обработваме.
                                    </p>
                                    <div className='mt-5 mb-3'>
                                        <h4 className='h5'>VII. СИГУРНОСТ НА ВАШИТЕ ДАННИ:</h4>
                                    </div>
                                    <p className='paragraph-3'>
                                        За осигуряване на адекватна защита на Вашите данни, ние прилагаме всички
                                        организационни и технически мерки, с които разполагаме, предвид нивото на
                                        техническия прогрес и оценката на рисковете от засягане на тяхната сигурност.
                                    </p>
                                    <p className='paragraph-3'>
                                        С цел предотвратяване на случайно или неправомерно унищожаване, загуба, промяна,
                                        неразрешено разкриване или достъп до прехвърлени, съхранявани или обработени по
                                        друг начин лични данни сме предприели мерки като например криптиране,
                                        псевдонимизация, утвърдени процедури за редовно изпитване, преценяване и оценка
                                        на ефективността на техническите и организационните мерки. Прилагаме механизми
                                        за своевременно възстановяване на наличността и достъпа до личните данни в
                                        случай на физически или технически инцидент и др.
                                    </p>
                                    <p className='paragraph-3'>
                                        Въпреки предприетите мерки, ако все пак бъде нарушена сигурността на Вашите
                                        данни, която съставлява висок риск за Вашите права и свободи, ще Ви информираме
                                        за това, както и за предприетите от нас мерки, при първа възможност.

                                    </p>

                                    <div className='mt-5 mb-3'>
                                        <h4 className='h5'>VIII. БИСКВИТКИ:</h4>
                                    </div>
                                    <p className='paragraph-3'>
                                        Бисквитките са малки текстови файлове, които уебсайтът може да запише на Вашия
                                        компютър или мобилно устройство, когато посещавате страница или сайт.
                                        Бисквитката ще помогне на сайта или на други сайтове да разпознаят вашето
                                        устройство следващия път, когато го посетите.
                                    </p>
                                    <p className='paragraph-3'>
                                        Бисквитките изпълняват множество различни функции. Например, те ни помагат да
                                        анализираме колко добре се представят нашите сайтове, да Ви показваме реклами,
                                        които вярваме, че са релевантни на интереси Ви или ни позволяват да Ви
                                        препоръчаме съдържание, което смятаме, че ще Ви бъде интересно.
                                    </p>
                                    <p className='paragraph-3'>
                                        Бисвкитките, които използваме са анонимни и не съдържат никакви лични данни.
                                    </p>
                                    <p className='paragraph-3'>
                                        Повече за бисквитките, както и начини да откажете използването на бисквитки на
                                        вашето устройство можете да откриете в нашата Политика за бисквитките.
                                    </p>
                                    <div className='mt-5 mb-3'>
                                        <h4 className='h5'>IX. РАЗКРИВАНЕ НА ЛИЧНИ ДАННИ ПРЕД ТРЕТИ ЛИЦА:</h4>
                                    </div>
                                    <p className='paragraph-3'>
                                        Разкриваме Вашите лични данни единствено пред:
                                    </p>
                                    <p className='paragraph-3'>
                                        9.1. държавни органи, институции и лица, на които сме длъжни да предоставяме
                                        лични данни по силата на закон;
                                    </p>
                                    <p className='paragraph-3'>
                                        9.2. лица, които по възлагане поддържат оборудване, софтуер и хардуер,
                                        използвани за обработка на лични данни, служители на Дружеството, адвокати,
                                        счетоводители или други лица, които по възлагане или по силата на закон имат
                                        достъп до личните Ви данни, обработвани от нас. Всички тези лица имат сключени с
                                        нас споразумения за конфиденциалност и са приели вътрешните ни правила за
                                        сигурност по обработка на лични данни;

                                    </p>
                                    <p className='paragraph-3'>
                                        9.3. наши партньори, които работят по възлагане на Дружеството на основата на
                                        договорни отношения и ни помагат да ви предоставим услугите си и с които имаме
                                        споразумения за запазване на конфиденциалността на информацията и защита на
                                        лични данни.

                                    </p>
                                    <p className='paragraph-3'>
                                        9.4. Използваме доставчици на услуги при обработването на лични данни за някои
                                        цели, като например измерване и проследяване на потребителското поведение в
                                        сайтовете ни, изпращане на имейл съобщения, споделяне на съдържание

                                    </p>
                                    <p className='paragraph-3'>
                                        9.4. Използваме доставчици на услуги при обработването на лични данни за някои
                                        цели, като например измерване и проследяване на потребителското поведение в
                                        сайтовете ни, изпращане на имейл съобщения, споделяне на съдържание от
                                        потребителите, вход с профил от социални мрежи и др. Тези компании са (без
                                        изброяването да е изчерпателно):

                                    </p>
                                    <p className='paragraph-3'>
                                        9.4. Използваме доставчици на услуги при обработването на лични данни за някои
                                        цели, като например измерване и проследяване на потребителското поведение в
                                        сайтовете ни, изпращане на имейл съобщения, споделяне на съдържание от
                                        потребителите, вход с профил от социални мрежи и др. Тези компании са (без
                                        изброяването да е изчерпателно):
                                    </p>
                                    <p className='paragraph-3'>
                                        - Google (с Google Analytics, Google Tag Manager, DoubleClick for Publishers,
                                        AdX, AdSense, AdWords, Google Plus): <a target='_blank'
                                                                                className='link link-underline link-default'
                                                                                href="https://privacy.google.com">https://privacy.google.com</a>

                                    </p>
                                    <p className='paragraph-3'>
                                        - YouTube: <a target='_blank' className='link link-underline link-default'
                                                      href="https://support.google.com/youtube/answer/2801895?hl=en-GB">https://support.google.com/youtube/answer/2801895?hl=en-GB</a>
                                    </p>
                                    <p className='paragraph-3'>
                                        - Twitter widgets:<a target='_blank'
                                                             className='link link-underline link-default'
                                                             href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a>
                                    </p>

                                    <div className='mt-5 mb-3'>
                                        <h4 className='h5'>X. КОМПЕТЕНТНИ ОРГАНИ ЗА ЗАЩИТА НА ЛИЧНИТЕ ДАННИ:</h4>
                                    </div>
                                    <p className='paragraph-3'>
                                        За жалба имате право да сезирате Комисия за защита на личните данни, която се
                                        намира в София, 1592, бул. Проф. Цветан Лазаров № 2, тел: +3592/91-53-518,
                                        е-mail: kzld@cpdp.bg,
                                    </p>
                                    <p className='paragraph-3'>
                                        Срокът за подаване на жалба до Комисията е 6 месеца от узнаване на нарушението,
                                        но не по-късно от две години от извършването му.

                                    </p>
                                    <p className='paragraph-3'>
                                        Актуалното съдържание на настоящата политика за поверителност се публикува на
                                        всеки един от нашите сайтове и отразява всички промени, които сме извършили в
                                        тях.
                                    </p>
                                </div>
                                <div className='inner-helper-page__author_wrap'>
                                    <p className='h-sup'>{this.state.date}</p>
                                    <div className='inner-helper-page__author'>
                                        <h5 className='h5'>{this.state.author ? `${this.state.author},` : null} <br/>
                                            {this.state.authorTitle}
                                        </h5>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }

    render() {
        const {intl} = this.props;
        return (
            <Switch>
                <Route path='/privacy-policy' exact component={this.renderPage}/>
            </Switch>

        )
    }
}

const mapStateToProps = (state) => {
    return {};
}

export default injectIntl(connect(
    mapStateToProps,
    {}
)(PrivacyPolicy));
